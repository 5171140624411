import React from 'react';
import PropTypes from 'prop-types';

import './about.sass';

export default function About(props) {
  const { aboutPage } = props;

  const cleanedAboutPage = aboutPage.split('<p>&nbsp;</p>').join('');

  return (
    <div className="about">
      <div dangerouslySetInnerHTML={{ __html: cleanedAboutPage }} />
    </div>
  );
}

About.propTypes = {
  displayableTime: PropTypes.string,
};
