import { throttle } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import html5player from '../../lib/player';
import { SECOND } from '../../utils/constants';
import classNames from 'classnames';
import PlayerControlsContainer from '../../containers/player-controls/player-controls';
import PlayerControls from '../player-controls/player-controls';

import './video-player.sass';

const SYNC_VIDEO_INTERVAL = SECOND * 30;
const ACCEPTABLE_OUT_OF_SYNC_RANGE = 3;

export class VideoPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoReadytoSync: false,
      playerOverlayActive: false,
      creditsActive: false,
      commentBoxActive: false,
    };

    this.player = null;
    this.syncInterval = null;
    this.mouseMoveTimeOut = null;
    this._isMounted = false;

    this.throttledTimeUpdate = throttle(this.handleTimeUpdate, SECOND);

    this.playerOptions = {
      videoId: this.props.video.VideoLocation,
      PlaylistID: this.props.video.PlaylistID,
      autoPlay: true,
      background: true,
      loop: false,
      onCanPlay: this.handleCanPlay,
      onVideoPlay: this.handleVideoPlay,
      onLoadedData: this.handleLoadedData,
      onTimeUpdate: this.throttledTimeUpdate,
      getShouldPlayerBeActive: this.getShouldPlayerBeActive,
    };
  }

  componentDidMount() {
    const { currentPlaylistID, video, activePlaylist } = this.props;
    const { isClock } = activePlaylist;
    const { PlaylistID, OffsetSec } = video;
    this.player = new html5player(this.playerOptions);

    this.player.setup();
    this.startSyncInterval();

    if (currentPlaylistID === PlaylistID) {
      this.props.setActivePlayerElement(this.player);
    }
    this._isMounted = true;

    if (!isClock) {
      this.player.player.setCurrentTime(OffsetSec);
    }
    if (currentPlaylistID === PlaylistID) {
      this.props.setLoadedData(false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      secondsFromMidnight,
      video,
      currentPlaylistID,
      activePlaylist,
      shouldPlayerBeActive,
    } = this.props;
    const { isClock } = activePlaylist;
    const { ClockStartTime, PlaylistID, OffsetSec } = video;
    const { videoReadyToSync } = this.state;

    if (
      currentPlaylistID === PlaylistID &&
      isClock &&
      !prevProps.activePlaylist.isClock
    ) {
      // If the user is returning to synced from unsynced on same video,
      // we need to sync the video
      this.syncVideoToClock();
      // play in case it was paused
      this.playVideo();
    }

    if (
      currentPlaylistID === PlaylistID &&
      prevProps.currentPlaylistID !== currentPlaylistID
    ) {
      this.props.setActivePlayerElement(this.player);
    }

    if (isClock) {
      if (
        prevProps.secondsFromMidnight !== secondsFromMidnight &&
        videoReadyToSync &&
        currentPlaylistID === PlaylistID
      ) {
        this.syncVideoToClock();
        this.setState({
          videoReadyToSync: false,
        });
      }
      const timeToVideo = ClockStartTime - secondsFromMidnight;
      // Start next clock
      if (timeToVideo <= 1 && currentPlaylistID !== video.PlaylistID) {
        this.playVideo();
      }
    } else if (shouldPlayerBeActive && !prevProps.shouldPlayerBeActive) {
      this.playVideo();
    }
    if (
      prevProps.currentPlaylistID === PlaylistID &&
      currentPlaylistID !== PlaylistID
    ) {
      if (this.player) {
        this.player.player.pause();
        this.player.player.setCurrentTime(OffsetSec);
      }
    }
  }

  componentWillUnmount() {
    if (this.syncInterval) {
      clearInterval(this.syncInterval);
    }

    if (this.mouseMoveTimeOut) {
      clearTimeout(this.mouseMoveTimeOut);
    }
    if (this.player) {
      this.player.remove();
    }
    this._isMounted = false;
  }

  handleCommentClick = commentBoxActive => {
    const { player } = this.player;

    if (commentBoxActive) {
      player.pause();
    } else {
      player.play();
    }

    this.setState({
      playerOverlayActive: true,
      commentBoxActive,
    });
    if (this.mouseMoveTimeOut) {
      clearTimeout(this.mouseMoveTimeOut);
    }

    if (!commentBoxActive) {
      this.mouseMoveTimeOut = setTimeout(() => {
        this.handleMouseLeave();
      }, 750);
    }
  };

  handleCreditsClick = creditsActive => {
    this.setState({
      playerOverlayActive: true,
      creditsActive,
    });
    if (this.mouseMoveTimeOut) {
      clearTimeout(this.mouseMoveTimeOut);
    }

    if (!creditsActive) {
      this.mouseMoveTimeOut = setTimeout(() => {
        this.handleMouseLeave();
      }, 750);
    }
  };

  handleMouseLeave = () => {
    const { creditsActive, commentBoxActive } = this.state;

    if (!creditsActive && !commentBoxActive) {
      this.setState({
        playerOverlayActive: false,
      });
    }
  };

  handleMouseMove = () => {
    const { creditsActive, commentBoxActive } = this.state;

    if (!creditsActive && !commentBoxActive) {
      if (this._isMounted && this.props.shouldPlayerBeActive) {
        this.setState({
          playerOverlayActive: true,
        });
        if (this.mouseMoveTimeOut) {
          clearTimeout(this.mouseMoveTimeOut);
        }

        this.mouseMoveTimeOut = setTimeout(() => {
          this.handleMouseLeave();
        }, 750);
      }
    }
  };

  getShouldPlayerBeActive = () => {
    return this.props.shouldPlayerBeActive;
  };

  playVideo = () => {
    return this.player.player.play();
  };

  startSyncInterval = () => {
    this.syncInterval = setInterval(
      this.setVideoReadyToSync,
      SYNC_VIDEO_INTERVAL,
    );
  };

  handleCanPlay = () => {
    this.props.setPlayerCanPlay();
  };

  handleVideoPlay = () => {
    const {
      video,
      secondsFromMidnight,
      activePlaylist,
      shouldPlayerBeActive,
    } = this.props;
    const { ClockStartTime, PlaylistID, OffsetSec } = video;
    const { player } = this.player;

    let timeToVideo = ClockStartTime - secondsFromMidnight;

    if (activePlaylist.isClock) {
      if (this.props.currentPlaylistID === PlaylistID) {
        this.syncVideoToClock();
      } else if (timeToVideo > 1) {
        player.pause();
      }
    } else {
      if (!shouldPlayerBeActive) {
        player.pause();
        player.setCurrentTime(OffsetSec);
      }
    }
  };

  handlePlayerSeeked = () => {
    // restart sync interval when a user seeks
    if (this.syncInterval) {
      clearInterval(this.syncInterval);

      this.startSyncInterval();
    }
  };

  handleLoadedData = () => {
    const { video, currentPlaylistID } = this.props;
    const { OffsetSec, PlaylistID } = video;
    const { player } = this.player;

    // Seek to start position
    if (currentPlaylistID !== PlaylistID) {
      player.setCurrentTime(OffsetSec);
    } else {
      this.props.setLoadedData(true);
    }
  };

  handleTimeUpdate = currentTime => {
    const {
      video,
      secondsFromMidnight,
      activePlaylist,
      currentPlaylistID,
    } = this.props;
    const { isClock } = activePlaylist;
    const { ClockStartTime, OffsetSec, PlayDuration, PlaylistID } = video;
    if (currentPlaylistID === PlaylistID) {
      this.props.setPlayerCurrentTime(currentTime);
    }

    if (isClock) {
      // To prevent the video from showing the end card, we need to sync the video
      // in the last few seconds if it is out of sync.
      const assumedTime = secondsFromMidnight - ClockStartTime + OffsetSec;
      const timeDifference = Math.abs(assumedTime - currentTime);

      if (timeDifference > ACCEPTABLE_OUT_OF_SYNC_RANGE) {
        if (currentTime >= PlayDuration * 60 - 2) {
          this.syncVideoToClock();
        }
      }
    }
  };

  setVideoReadyToSync = () => {
    const { video, secondsFromMidnight, currentPlaylistID } = this.props;
    const { ClockStartTime, OffsetSec, PlaylistID } = video;
    const { player } = this.player;
    const assumedTime = secondsFromMidnight - ClockStartTime + OffsetSec;

    if (player && currentPlaylistID === PlaylistID) {
      player.getCurrentTime().then(actualTime => {
        const timeDifference = Math.abs(assumedTime - actualTime);

        if (timeDifference > ACCEPTABLE_OUT_OF_SYNC_RANGE) {
          this.setState({
            videoReadyToSync: true,
          });
        }
      });
    }
  };

  syncVideoToClock = callback => {
    const { video, secondsFromMidnight } = this.props;
    const { ClockStartTime, OffsetSec } = video;
    const { player } = this.player;
    const videoPosition = secondsFromMidnight - ClockStartTime + OffsetSec;

    if (videoPosition > -1) {
      player.setCurrentTime(videoPosition + 0.3);
    }
  };

  render() {
    const { playerOverlayActive, creditsActive } = this.state;
    const { video, canPlay, shouldPlayerBeActive } = this.props;

    const playerOverlayClasses = classNames({
      'player-overlay': true,
      'credits-active': creditsActive,
      'is-displayed': shouldPlayerBeActive,
      'is-active': playerOverlayActive && canPlay,
    });

    const { VideoLocation, PlaylistID } = video;

    return (
      <div
        onMouseLeave={this.handleMouseLeave}
        onMouseMove={this.handleMouseMove}
        onClick={this.handleMouseMove}
        className={'video-player-group'}
      >
        <div id={`video-${VideoLocation}-${PlaylistID}`}>
          {this.props.children}
        </div>
        <div className={playerOverlayClasses}>
          <PlayerControlsContainer>
            <PlayerControls
              handleCommentClick={this.handleCommentClick}
              handleCreditsClick={this.handleCreditsClick}
              shouldPlayerBeActive={shouldPlayerBeActive}
              onFullScreenClick={this.props.onFullScreenClick}
              player={this.player}
              onPlayerSeeked={this.handlePlayerSeeked}
            />
          </PlayerControlsContainer>
        </div>
      </div>
    );
  }
}

VideoPlayer.propTypes = {
  children: PropTypes.element,
  currentPlaylistID: PropTypes.number,
  secondsFromMidnight: PropTypes.number,
  video: PropTypes.object,
};

export default VideoPlayer;
