import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import middlewares from './middlewares';
import rootReducer from './reducers';

export default function configureStore({ initialState = {} }) {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user'],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(
    persistedReducer,
    initialState,
    applyMiddleware(...middlewares),
  );

  const persistor = persistStore(store);
  return { store, persistor };
}
