import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { cloneElement, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPlaylists, getActivePlaylist } from '../../redux/selectors/player';
import {
  setActivePlaylist,
  setCurrentVideo,
  savePlayerState,
} from '../../redux/actions/player';

class PlaylistsContainer extends Component {
  handlePlaylistClick = playlist => {
    if (
      (!isEmpty(this.props.activePlaylist) &&
        this.props.activePlaylist.fileName !== playlist.fileName) ||
      (!isEmpty(this.props.activePlaylist) &&
        this.props.activePlaylist.isClock !== playlist.isClock)
    ) {
      this.props.setActivePlaylist(playlist);
    }
  };

  render() {
    const { children, playlists, savePlayerState } = this.props;

    return cloneElement(children, {
      playlists,
      savePlayerState,
      handlePlaylistClick: this.handlePlaylistClick,
    });
  }
}

PlaylistsContainer.propTypes = {
  children: PropTypes.element.isRequired,
  playlists: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    playlists: getPlaylists(state),
    activePlaylist: getActivePlaylist(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setActivePlaylist,
      setCurrentVideo,
      savePlayerState,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistsContainer);
