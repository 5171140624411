export function getActivePlayerElement({ player }) {
  return player.activePlayerElement;
}

export function getActivePlaylist({ player }) {
  return player.activePlaylist || {};
}

export function getCurrentVideo({ player }) {
  return player.currentVideo || {};
}

export function getNextVideo({ player }) {
  return player.nextVideo || {};
}

export function getPlaylists({ player }) {
  return player.playlists;
}

export function getPlayerCurrentTime({ player }) {
  return player.currentTime;
}

export function getPlayerCanPlay({ player }) {
  return player.canPlay;
}

export function getResumingFromLocalStorage({ player }) {
  return player.resumingFromLocalStorage;
}

export function getLoadedData({ player }) {
  return player.loadedData;
}
