import PropTypes from 'prop-types';
import { cloneElement, Component } from 'react';
import { connect } from 'react-redux';
import { getPlaylists } from '../../redux/selectors/player';
import { getUniqueVideosFromPlaylists } from '../../utils/video-check';

class VideoCheckContainer extends Component {
  render() {
    const { children, playlists } = this.props;
    const uniqueVideos = getUniqueVideosFromPlaylists(playlists);

    return cloneElement(children, {
      uniqueVideos,
      playlists,
    });
  }
}

VideoCheckContainer.propTypes = {
  playlists: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    playlists: getPlaylists(state),
  };
}
export default connect(mapStateToProps, null)(VideoCheckContainer);
