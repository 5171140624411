import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export default class DropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownActive: false,
    };
  }

  handleMouseLeave = () => {
    this.setState({
      dropDownActive: false,
    });
  };

  handleMouseOver = () => {
    this.setState({
      dropDownActive: true,
    });
  };

  renderDropDown = () => {
    const { item } = this.props;
    const { subPages } = item;
    const subPageKeys = Object.keys(subPages);
    return subPageKeys.map(key => {
      const { title, path } = subPages[key];

      return (
        <Link className="drop-down-item" to={`/${path}`}>
          {title}
        </Link>
      );
    });
  };
  render() {
    const { item } = this.props;
    const { title } = item;
    const { dropDownActive } = this.state;

    const dropDownClasses = classNames({
      'drop-down': true,
      'is-active': dropDownActive,
    });
    return (
      <div
        onMouseLeave={this.handleMouseLeave}
        onMouseOver={this.handleMouseOver}
        className="drop-down-group"
      >
        {title} <span className="drop-down-triangle">&#x25bc;</span>
        <div
          onClick={this.handleMouseLeave}
          onMouseLeave={this.handleMouseLeave}
          className={dropDownClasses}
        >
          {this.renderDropDown()}
        </div>
      </div>
    );
  }
}
