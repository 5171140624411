export function getUniqueVideosFromPlaylists(playlists) {
  if (!playlists.length) {
    return;
  }

  let uniqueVideos = {};
  playlists.forEach(playlist => {
    const { videos } = playlist;
    videos.forEach(video => {
      const { VideoLocation } = video;
      if (!uniqueVideos[VideoLocation]) {
        uniqueVideos[VideoLocation] = video;
      }
    });
  });
  return uniqueVideos;
}
