import PropTypes from 'prop-types';
import { cloneElement, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fastForward, setCurrentTime } from '../../redux/actions/clock';
import { setCurrentVideo } from '../../redux/actions/player';
import { getSecondsFromMidnight } from '../../redux/selectors/clock';
import {
  getCurrentVideo,
  getNextVideo,
  getActivePlaylist,
  getPlayerCurrentTime,
  getPlayerCanPlay,
  getLoadedData,
} from '../../redux/selectors/player';
import { SECOND } from '../../utils/constants';

class HomeContainer extends Component {
  constructor() {
    super();

    this.state = {
      fadeInActive: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    const {
      activePlaylist,
      currentVideo,
      secondsFromMidnight,
      currentTime,
    } = nextProps;
    const { PlayDuration, ClockStartTime, OffsetSec } = currentVideo;
    const { isClock } = activePlaylist;

    const assumedTime = secondsFromMidnight - ClockStartTime + OffsetSec;
    const timeDifference = assumedTime - currentTime;

    const shouldFadeIn =
      isClock &&
      timeDifference < 0 &&
      (ClockStartTime + PlayDuration * 60 - 2 <= secondsFromMidnight ||
        ClockStartTime + 2 > secondsFromMidnight);

    if (shouldFadeIn && !this.state.fadeInActive) {
      this.setState({
        fadeInActive: true,
      });

      setTimeout(() => {
        this.setState({
          fadeInActive: false,
        });
      }, SECOND * 4);
    }
  }
  render() {
    const {
      children,
      currentVideo = {},
      nextVideo,
      secondsFromMidnight,
      activePlaylist,
      currentTime,
      canPlay,
      loadedData,
    } = this.props;
    const { fadeInActive } = this.state;

    const { isClock } = activePlaylist;
    const { AddHour, PlayDuration, ClockStartTime } = currentVideo;
    const hourToDisplay = Math.floor((secondsFromMidnight / 60 / 60) % 12);
    let hour;

    const currentVideoIsActive =
      secondsFromMidnight < PlayDuration * 60 + ClockStartTime - 1.5;

    if (AddHour && canPlay && isClock && currentVideoIsActive) {
      hour = hourToDisplay === 0 ? 12 : hourToDisplay;
    }

    if (!isClock && canPlay) {
      hour = 'Unsynced';
    }

    return cloneElement(children, {
      currentVideo,
      nextVideo,
      secondsFromMidnight,
      hour,
      activePlaylist,
      currentTime,
      canPlay,
      loadedData,
      fadeInActive,
      fastForward: this.props.fastForward,
    });
  }
}

HomeContainer.propTypes = {
  children: PropTypes.element.isRequired,
  currentVideo: PropTypes.object,
  fastForward: PropTypes.func,
  nextVideo: PropTypes.object,
  secondsFromMidnight: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    currentVideo: getCurrentVideo(state),
    nextVideo: getNextVideo(state),
    secondsFromMidnight: getSecondsFromMidnight(state),
    activePlaylist: getActivePlaylist(state),
    currentTime: getPlayerCurrentTime(state),
    canPlay: getPlayerCanPlay(state),
    loadedData: getLoadedData(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCurrentTime,
      fastForward,
      setCurrentVideo,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
