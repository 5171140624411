import { combineReducers } from 'redux';
import clock from './reducers/clock';
import player from './reducers/player';
import user from './reducers/user';
import about from './reducers/about';

export default combineReducers({
  player,
  clock,
  user,
  about,
});
