import React, { Component } from 'react';
import classNames from 'classnames';
import './player-controls.sass';
import { SEEK_RANGE } from '../../utils/constants';
import ThumbIcon from '../icons/thumb';

export default class PlayerControls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creditsActive: false,
      commentBoxActive: false,
      thumb: '',
      comment: '',
      isReportingProblem: false,
      returnToSynced: false,
    };
  }

  renderPlayPause = () => {
    const { isPaused } = this.props;
    const playPauseText = isPaused ? 'Play' : 'Pause';

    return (
      <button onClick={this.props.onPlayPauseClick} className="play-pause">
        {playPauseText}
      </button>
    );
  };

  handleFullScreenClick = () => {
    this.props.onFullScreenClick();
  };

  handleCommentChange = event => {
    const { value } = event.target;

    this.setState({ comment: value });
  };

  handleProblemClick = () => {
    this.setState({
      isReportingProblem: !this.state.isReportingProblem,
    });
  };

  onSubmitComment = () => {
    const { isReportingProblem, comment } = this.state;
    this.props.handleSubmitComment({
      comment,
      isReportingProblem,
    });
    this.onCloseComment();
  };

  onCreditsClick = () => {
    this.setState({
      creditsActive: true,
    });

    this.props.handleCreditsClick(true);
  };

  onCreditsCloseClick = () => {
    this.setState({
      creditsActive: false,
    });

    this.props.handleCreditsClick(false);
  };

  onPrevClick = () => {
    const { activePlaylist } = this.props;
    const { isClock } = activePlaylist;
    if (isClock) {
      this.props.togglePlayerSynced();
    }

    this.props.playPreviousVideo();
  };

  onNextClick = () => {
    const { activePlaylist } = this.props;
    const { isClock } = activePlaylist;
    if (isClock) {
      this.props.togglePlayerSynced();
    }

    this.props.playNextVideo();
  };

  onCommentClick = () => {
    const { activePlaylist } = this.props;
    const { isClock } = activePlaylist;
    if (isClock) {
      this.setState({ returnToSynced: true });
      this.props.togglePlayerSynced();
    }

    this.setState({ commentBoxActive: true });
    this.props.handleCommentClick(true);
  };

  onCloseComment = () => {
    const { returnToSynced } = this.state;
    const { activePlaylist } = this.props;
    const { isClock } = activePlaylist;

    if (returnToSynced && !isClock) {
      this.props.togglePlayerSynced();
    }

    this.setState({
      commentBoxActive: false,
      returnToSynced: false,
    });

    this.props.handleCommentClick(false);
  };

  rewindClicked = () => {
    const { activePlaylist } = this.props;
    const { isClock } = activePlaylist;
    if (isClock) {
      this.props.togglePlayerSynced();
    }

    this.props.onRewindClick();
    this.props.onPlayerSeeked();
  };

  fastForwardClicked = () => {
    const { activePlaylist } = this.props;
    const { isClock } = activePlaylist;
    if (isClock) {
      this.props.togglePlayerSynced();
    }

    this.props.onFastForwardClick();
    this.props.onPlayerSeeked();
  };

  thumbsUpClicked = () => {
    this.setState({
      thumb: 'like',
    });
    this.props.onLikeClick(true);
  };

  thumbsDownClicked = () => {
    this.setState({
      thumb: 'dislike',
    });
    this.props.onLikeClick(false);
  };

  renderFullscreenButton = () => {
    const fullScreenText =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
        ? 'Exit Full Screen'
        : 'Full Screen';
    return (
      <button
        className="fullscreen-button"
        onClick={this.handleFullScreenClick}
      >
        {fullScreenText}
      </button>
    );
  };

  renderSyncedButton = () => {
    const { activePlaylist } = this.props;
    const { isClock, canPlaySynced } = activePlaylist;
    if (isClock) {
      return (
        <button
          className={'play-pause'}
          onClick={this.props.togglePlayerSynced}
        >
          Play Unsynced
        </button>
      );
    }
    if (!isClock && canPlaySynced) {
      return (
        <button
          className={'play-pause'}
          onClick={this.props.togglePlayerSynced}
        >
          Play Synced
        </button>
      );
    }
    return null;
  };

  renderRatingButtons = () => {
    const thumbsClasses = classNames({
      thumbs: true,
      [this.state.thumb]: true,
    });

    return (
      <div className={thumbsClasses}>
        <ThumbIcon onClick={this.thumbsUpClicked} parentClass="thumbsup" />
        <ThumbIcon onClick={this.thumbsDownClicked} parentClass="thumbsdown" />
      </div>
    );
  };

  renderCommentButton = () => {
    return (
      <div onClick={this.onCommentClick} className="comment-button">
        Comment/Report Problem
      </div>
    );
  };

  renderCenterControls = () => {
    const rewindButton = (
      <button onClick={this.rewindClicked} className="prev-button">
        - {SEEK_RANGE}s
      </button>
    );

    const fastForwardButton = (
      <button onClick={this.fastForwardClicked} className="prev-button">
        + {SEEK_RANGE}s
      </button>
    );

    return (
      <div className="center-controls">
        <div className="side-controls">
          <button onClick={this.onPrevClick} className="prev-button">
            Prev
          </button>
          {rewindButton}
        </div>
        <div className="side-controls">
          {fastForwardButton}
          <button onClick={this.onNextClick} className="next-button">
            Next
          </button>
        </div>
      </div>
    );
  };

  render() {
    const { creditsActive, commentBoxActive, isReportingProblem } = this.state;
    const { currentVideo } = this.props;
    const { CreditText = '' } = currentVideo;
    const formattedCreditText = CreditText.split('\n')
      .filter(item => item)
      .join('<p/>');

    const creditsButton = CreditText ? (
      <div onClick={this.onCreditsClick} className="credits-button">
        Credits
      </div>
    ) : null;

    if (!creditsActive && !commentBoxActive) {
      return (
        <div className="player-controls">
          {this.renderFullscreenButton()}
          {this.renderPlayPause()}
          {creditsButton}
          {this.renderCenterControls()}
          {this.renderSyncedButton()}
          {this.renderRatingButtons()}
          {this.renderCommentButton()}
        </div>
      );
    }

    if (creditsActive) {
      return (
        <div className="player-controls">
          <div onClick={this.onCreditsCloseClick} className="close-credits">
            Close credits
          </div>

          <div className="credits">
            <div
              className="credits-text"
              dangerouslySetInnerHTML={{ __html: formattedCreditText }}
            />
          </div>
        </div>
      );
    }
    if (commentBoxActive) {
      return (
        <div className="comment-box">
          <button onClick={this.onCloseComment} className="close-comment">
            Cancel
          </button>
          <textarea
            className="comment-input"
            onChange={this.handleCommentChange}
          />
          <div>
            <input
              value={isReportingProblem}
              onChange={this.handleProblemClick}
              type="checkbox"
              name="problem"
            />
            <span>Report Problem</span>
          </div>
          <button className="comment-submit" onClick={this.onSubmitComment}>
            Submit
          </button>
        </div>
      );
    }
  }
}
