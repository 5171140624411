import classNames from 'classnames';
import { CircleLoader } from 'react-spinners';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import VideoPlayerContainer from '../../containers/video-player/video-player';
import VideoPlayer from '../video-player/video-player';
import './home.sass';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videos: [],
    };

    this.mouseMoveTimeOut = null;
    this.videoPlayers = null;
    this._isMounted = false;
  }

  componentDidMount() {
    const { currentVideo, nextVideo } = this.props;

    window.addEventListener('keydown', this.handleKeyDown);

    this.setState({
      videos: [currentVideo, nextVideo],
    });

    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentVideo, nextVideo } = this.props;
    // Initial clock is set
    // or new playlist has been selected
    if (isEmpty(prevProps.currentVideo) && !isEmpty(currentVideo)) {
      this.setState({
        videos: [currentVideo, nextVideo],
      });
    } else {
      if (prevProps.currentVideo.PlaylistID !== currentVideo.PlaylistID) {
        this.setState({
          videos: [currentVideo, nextVideo],
        });
      }
    }
  }

  commponentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.mouseMoveTimeOut);
  }

  onFullScreenClick = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    } else {
      if (this.videoPlayers.requestFullscreen) {
        this.videoPlayers.requestFullscreen();
      } else if (this.videoPlayers.mozRequestFullScreen) {
        /* Firefox */
        this.videoPlayers.mozRequestFullScreen();
      } else if (this.videoPlayers.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.videoPlayers.webkitRequestFullscreen();
      } else if (this.videoPlayers.msRequestFullscreen) {
        /* IE/Edge */
        this.videoPlayers.msRequestFullscreen();
      }
    }
  };

  createVideoComponent = ({ video, shouldPlayerBeActive }) => {
    const { currentVideo } = this.props;
    const { PlaylistID: currentPlaylistID } = currentVideo;
    const { VideoLocation } = video;
    if (VideoLocation) {
      return (
        <VideoPlayerContainer video={video}>
          <VideoPlayer
            onFullScreenClick={this.onFullScreenClick}
            currentPlaylistID={currentPlaylistID}
            shouldPlayerBeActive={shouldPlayerBeActive}
          />
        </VideoPlayerContainer>
      );
    }
  };

  renderPlayers = () => {
    const { secondsFromMidnight, activePlaylist, currentTime } = this.props;
    const { isClock } = activePlaylist;
    const { videos } = this.state;

    return videos.map((video, i) => {
      if (isEmpty(video)) {
        return null;
      }
      const { ClockStartTime, PlayDuration, OffsetSec } = video;

      let shouldPlayerBeActive = false;
      if (isClock) {
        shouldPlayerBeActive =
          (i === 0 &&
            secondsFromMidnight < PlayDuration * 60 + ClockStartTime - 1.5) ||
          (i === 1 && secondsFromMidnight > ClockStartTime - 2);
      } else {
        shouldPlayerBeActive =
          (i === 0 && currentTime - OffsetSec < PlayDuration * 60 - 1) ||
          (i === 1 &&
            currentTime - videos[0].OffsetSec >
              videos[0].PlayDuration * 60 - 2);
      }

      const playerClasses = classNames({
        'video-player-container': true,
        'is-active': shouldPlayerBeActive,
        'full-screen-mode':
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement,
      });

      return (
        <div key={`video-${video.PlaylistID}`} className={playerClasses}>
          {this.createVideoComponent({ video, shouldPlayerBeActive })}
        </div>
      );
    });
  };

  render() {
    const {
      hour,
      activePlaylist,
      canPlay,
      loadedData,
      fadeInActive,
    } = this.props;

    const { isClock } = activePlaylist;

    const hourClasses = classNames({
      hour: true,
      unsynced: !isClock,
    });

    const hourMarkup =
      hour && canPlay && loadedData ? (
        <div className={hourClasses}>{hour}</div>
      ) : null;

    const loaderMarkup =
      !canPlay || !loadedData ? (
        <div className="loader">
          <CircleLoader color="#3d3d3d" sizeUnit={'px'} size={100} />
        </div>
      ) : null;

    const fadeInClasses = classNames('fade-in', {
      'is-active': fadeInActive,
    });
    // const fadeIn = isClock && fadeInActive ? <div className="fade-in" /> : null;
    // const fadeIn = true ? <div className="fade-in" /> : null;

    return (
      <div className="home">
        <div ref={el => (this.videoPlayers = el)} className="video-players">
          <div className={fadeInClasses} />
          {loaderMarkup}
          {hourMarkup}
          {this.renderPlayers()}
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  currentVideo: PropTypes.object,
  fastForward: PropTypes.func,
  secondsFromMidnight: PropTypes.number,
};

export default Home;
