export const UPDATE_CLOCK = 'UPDATE_CLOCK';
export const START_CLOCK = 'START_CLOCK';

export function setCurrentTime() {
  return (dispatch, getState) => {
    const now = new Date();
    const nextSecondsFromMidnight =
      now.getSeconds() + 60 * (now.getMinutes() + 60 * now.getHours());

    dispatch({
      type: UPDATE_CLOCK,
      payload: {
        secondsFromMidnight: nextSecondsFromMidnight,
      },
    });
  };
}

export function fastForward() {
  return (dispatch, getState) => {
    const state = getState();
    const { currentVideo } = state.player;

    const { PlayDuration, ClockStartTime } = currentVideo;

    dispatch({
      type: UPDATE_CLOCK,
      payload: {
        secondsFromMidnight: ClockStartTime + PlayDuration * 60 - 10,
      },
    });
  };
}
