import { UPDATE_ABOUT } from '../actions/about';
import createReducer from './create-reducer';

const INITIAL_STATE = {
  about: '',
  pages: {},
};

function updateAbout(state, { payload }) {
  return {
    ...state,
    ...payload,
  };
}

const handlers = {
  [UPDATE_ABOUT]: updateAbout,
};

export default createReducer(INITIAL_STATE, handlers);
