import React, { Component } from 'react';

import './video-check.sass';

export default class VideoCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videosLeft: 0,
      badVideos: [],
      checkingVideos: false,
    };
  }
  startVideoCheck = () => {
    const { uniqueVideos } = this.props;

    this.setState({
      videosLeft: Object.keys(uniqueVideos).length,
      checkingVideos: true,
    });

    Object.keys(uniqueVideos).forEach((video, i) => {
      setTimeout(
        function() {
          fetch(
            `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${video}?autoplay=0&amp;background=1`,
            {
              method: 'GET',
              cache: 'no-cache',
              mode: 'cors',
            },
          ).then(data => {
            if (data.status !== 200) {
              this.setState({
                badVideos: [...this.state.badVideos, uniqueVideos[video]],
              });
            }
          });
          this.setState({
            videosLeft: Object.keys(uniqueVideos).length - i - 1,
          });
        }.bind(this),
        1000 * i,
      );
    });

    this.setState({
      checkingVideos: false,
    });
  };

  renderBadVideos = () => {
    const { badVideos } = this.state;

    return badVideos.map(badVideo => {
      return (
        <div className="row">
          <span className="column">{badVideo.VideoLocation}</span>
          <span className="column">{badVideo.CombMinuteID}</span>
        </div>
      );
    });
  };
  renderBadVideoSection = () => {
    const { badVideos } = this.state;
    if (!badVideos.length) {
      return <div>No bad videos yet</div>;
    }

    return (
      <div>
        <div className="row">
          <span className="column">VideoLocation</span>
          <span className="column">CombMinuteID</span>
        </div>
        {this.renderBadVideos()}
      </div>
    );
  };

  renderButton = () => {
    if (this.state.checkingVideos || !this.props.uniqueVideos) {
      return null;
    }

    return (
      <button className="video-check-button" onClick={this.startVideoCheck}>
        Check videos
      </button>
    );
  };

  render() {
    const { uniqueVideos } = this.props;
    const { videosLeft } = this.state;
    const uniqueVideosLength = uniqueVideos
      ? Object.keys(uniqueVideos).length
      : null;

    const percentageComplete = videosLeft
      ? (1 - videosLeft / uniqueVideosLength) * 100
      : 0;
    const progressbarStyle = {
      width: `${percentageComplete}%`,
    };

    return (
      <div className="video-check-group">
        <div>Total Unique Videos: {uniqueVideosLength}</div>
        <div>{videosLeft ? `Videos left: ${videosLeft}` : ''}</div>
        <div className="progress-bar">
          <div style={progressbarStyle} className="progress-bar-fill"></div>
        </div>
        <div>{this.renderButton()}</div>
        <div>Bad Videos:</div>
        <div>{this.renderBadVideoSection()}</div>
      </div>
    );
  }
}
