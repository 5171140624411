import player from "@vimeo/player";
import _ from "lodash";

function Player(options) {
  const { videoId, PlaylistID, ...opts } = options;

  if (typeof player === "undefined") {
    throw new Error("player is required.");
  }
  this.videoId = videoId;
  this.PlaylistID = PlaylistID;

  this.buffering = false;
  this.qualities = null;
  this.loadStartTime = null;
  this.loadEndTime = null;
  this.assetType = null;
  this.failoverTimer = null;
  this.assetId = null;

  // the default options that can be overridden by passing an options object
  const defaults = {
    id: videoId,
    autoPlay: true,
    background: true,
    loop: false,
    autopause: false,
    width: "100%",
    height: "100%",
  };

  // whitelisted options for the player
  this.acceptedOptions = [
    // Vimeo API options
    "id", // // Required. Either the id or the url of the video.
    "url", // // Required. Either the id or the url of the video.
    "autopause", // // true	Pause this video automatically when another one plays.
    "autoplay", // false	Automatically start playback of the video. Note that this won’t work on some devices.
    "background", // false	Enable the player's background mode which hides the controls, autoplays and loops the video (available to Plus, PRO, or Business members).
    "byline", // true	Show the byline on the video.
    "color", // 00adef	Specify the color of the video controls. Colors may be overridden by the embed settings of the video.
    "controls", // true	This parameter will hide all elements in the player (play bar, sharing buttons, etc) for a chromeless experience. ⚠️Warning: When using this parameter, the play bar and UI will be hidden. To start playback for your viewers, you'll need to either enable autoplay or use our player SDK to start and control playback. (available to Plus, PRO, or Business members)
    "dnt", // false	Block the player from tracking any session data, including cookies.
    "height", // 	The exact height of the video. Defaults to the height of the largest available version of the video.
    "loop", // false	Play the video again when it reaches the end.
    "responsive", // false	Resize according their parent element (experimental)
    "maxheight", // 	Same as height, but video will not exceed the native size of the video.
    "maxwidth", // 	Same as width, but video will not exceed the native size of the video.
    "muted", // false	Mute this video on load. Required to autoplay in certain browsers.
    "playsinline", // true	Play video inline on mobile devices, to automatically go fullscreen on playback set this parameter to false.
    "portrait", // true	Show the portrait on the video.
    "quality", //	Vimeo Plus, PRO, and Business members can default an embedded video to a specific quality on desktop. Possible values: 4K, 2K, 1080p, 720p, 540p, 360p and 240p https://help.vimeo.com/hc/en-us/articles/224983008-Setting-default-quality-for-embedded-videos
    "speed", // false	Show the speed controls in the preferences menu and enable playback rate API (available to PRO and Business accounts).
    "texttrack", // 	Turn captions/subtitles on for a specific language by default. If you enter a language preference that hasn't yet been uploaded for your particular video, the text track parameter will be ignored, and your embedded video may load with CC or subtitles disabled by default. Supports lowercase language code (such as: fr, es, de, en). You can find a full list of popular language codes here.
    "title", // true	Show the title on the video.
    "transparent", // true	The responsive player and transparent background are enabled by default, to disable set this parameter to false.
    "width", // 	The exact width of the video. Defaults to the width of the largest available version of the video.

    // Custom options
    "PlaylistID",
    "getShouldPlayerBeActive",
    "onVideoFinish",
    "onLoadedData",
    "onTimeUpdate",
    "onCanPlay",
  ];
  this.customOptions = [
    "PlaylistID",
    "getShouldPlayerBeActive",
    "onVideoFinish",
    "onLoadedData",
    "onTimeUpdate",
    "onCanPlay",
  ];

  // merge with `this` for data attrs.
  const options1 = Object.assign({}, this, opts);
  // merge options1 with the defaults
  const options2 = Object.assign({}, defaults, options1);

  this.options = options2;
  // filter out options jwplayer doesn't need.
  this.vimeoOptions = _.pick(this.options, this.acceptedOptions);
  // get rid of jwplayer specific options.
  this.playerOpts = _.omit(this.options, this.acceptedOptions);
}

/**
 * Setup the player with a set of options.
 * Also attach the player events and PubSub subscriptions.
 *
 * @param  {object} options An object of options for the player.
 * @return {void}
 */
Player.prototype.setup = function setup(options) {
  const filteredOptions = Object.keys(this.vimeoOptions).reduce((acc, curr) => {
    if (!this.customOptions.includes(curr)) {
      return {
        ...acc,
        [curr]: this.vimeoOptions[curr],
      };
    }
    return acc;
  }, {});

  this.player = new player(
    `video-${this.videoId}-${this.PlaylistID}`,
    filteredOptions
  );
  this.addPlayerEvents();
};

/**
 * Attach all the player events. Also add PubSub publish events.
 *
 * @param {object} options The players options
 * @return {void}
 */
Player.prototype.addPlayerEvents = function addPlayerEvents() {
  // All of these events are from HTML5 video events.
  // https://www.w3.org/2010/05/video/mediaevents.html
  // The only exception is `metrics` which is a custom event
  // added to the player-swf player

  this.on("play", () => {
    console.log("Player", ": onPlay", this.getTimestamp());

    this.options.onVideoPlay && this.options.onVideoPlay();
  });

  this.on("timeupdate", (data) => {
    if (this.options.getShouldPlayerBeActive()) {
      this.options.onTimeUpdate && this.options.onTimeUpdate(data.seconds);
    }
  });

  this.on("pause", () => {
    console.log("Player", ": onPause", this.getTimestamp());
  });

  this.on("ready", () => {
    // const assetType = this.playerOpts.assetType.toLowerCase();

    console.log("Player", ": onReady", this.getTimestamp());

    this.player.src({
      src: this.file,
      type: "application/x-mpegURL",
    });

    this.player.play();
  });

  this.on("loaded", (data) => {
    console.log("Player", ": onLoaded", this.getTimestamp());

    if (this.buffering) {
      this.buffering = false;
    }

    this.options.onCanPlay && this.options.onCanPlay();
  });

  // If we get an error try another playlist url until
  // we don't have any urls to try.
  this.on("error", (event) => {
    const errorMessage = [
      `\n player Error Message: ${event.target.player.error_.message}`,
      `\n HTML5 Error Code: ${event.target.player.error_.code}`,
      `\n mediaURL:' + ${this.file}`,
    ].toString();

    console.log("Player", ": onError", errorMessage);

    this.remove();
  });

  this.on("playing", () => {
    console.log("Player", ": onPlaying", this.getTimestamp());

    if (this.failoverTimer) {
      clearTimeout(this.failoverTimer);
    }
  });

  this.on("loadeddata", (data) => {
    console.log("Player", ": onLoadedData", this.getTimestamp());

    if (this.failoverTimer) {
      clearTimeout(this.failoverTimer);
    }

    if (this.options.onLoadedData) {
      this.options.onLoadedData();
    }
  });

  this.on("ended", () => {
    console.log("Player", "onEnded", this.getTimestamp());

    this.options.onVideoFinish && this.options.onVideoFinish();
  });
};

/**
 * Destroys the player and all it's events.
 *
 * @return {void}
 */
Player.prototype.remove = function remove() {
  this.player.destroy();
};

/**
 * Wrapper for the players get methods
 *
 * @param  {string} key uppercase string of data to get from player.
 * @return {object} data returned from the key
 */
Player.prototype.get = function get(key) {
  this.player[`get ${key}`]();
};

/**
 * Wrapper for the players events
 *
 * @param  {string} The event listen to.
 * @param  {callback} Callback method.
 * @return {void}
 */
Player.prototype.on = function on(event, callback) {
  this.player.on(event, callback.bind(this));
};

Player.prototype.getTimestamp = function getTimestamp() {
  return Math.floor(new Date().valueOf() / 1000);
};

export default Player;
