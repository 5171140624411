import React, { Component } from 'react';
import ClockContainer from '../../containers/clock/clock';
import { Link } from 'react-router-dom';
import Clock from '../clock/clock';
import DropDown from './dropdown';

import './navbar.sass';

export default class NavBar extends Component {
  renderPagesNavItems = () => {
    const { pages } = this.props;
    const pageKeys = Object.keys(pages);

    return pageKeys.map(key => <DropDown key={key} item={pages[key]} />);
  };

  render() {
    const { activePlaylist } = this.props;
    const { listName } = activePlaylist;
    return (
      <div className="nav-bar">
        <div className="title-group">
          <Link to="/" className="title link">
            The Video Clock
          </Link>{' '}
          (beta)
        </div>
        {listName}
        <div className="nav-items">
          <Link className="link" to="/playlists">
            Select Clock
          </Link>
          {this.renderPagesNavItems()}
          <ClockContainer>
            <Clock />
          </ClockContainer>
        </div>
      </div>
    );
  }
}
