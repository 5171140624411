import PropTypes from 'prop-types';
import { cloneElement, Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAboutPage } from '../../redux/selectors/about';
import { fetchAboutPage } from '../../redux/actions/about';

class NavBarContainer extends Component {
  componentDidMount() {
    this.props.fetchAboutPage();
  }
  render() {
    const { children, aboutPage } = this.props;
    return cloneElement(children, {
      aboutPage,
    });
  }
}

NavBarContainer.propTypes = {
  aboutPage: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    aboutPage: getAboutPage(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAboutPage,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBarContainer);
