import React from 'react';
import PropTypes from 'prop-types';

export default function Clock(props) {
  const { displayableTime } = props;

  return <div className="clock">{displayableTime}</div>;
}

Clock.propTypes = {
  displayableTime: PropTypes.string,
};
