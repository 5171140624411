// support >= ie9
import 'react-app-polyfill/ie9';
import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';

import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/app/app';
import AppContainer from './containers/app/app';
import createStore from './redux/store';
import * as serviceWorker from './utils/serviceWorker';

const { store, persistor } = createStore({});

render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AppContainer>
        <App store={store} />
      </AppContainer>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
