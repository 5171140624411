import PropTypes from 'prop-types';
import { cloneElement, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSecondsFromMidnight } from '../../redux/selectors/clock';
import {
  setPlayerCurrentTime,
  setPlayerCanPlay,
  setActivePlayerElement,
  setLoadedData,
} from '../../redux/actions/player';
import {
  getPlayerCurrentTime,
  getActivePlaylist,
  getPlayerCanPlay,
} from '../../redux/selectors/player';

class VideoPlayerContainer extends Component {
  render() {
    const {
      children,
      secondsFromMidnight,
      video,
      setPlayerCurrentTime,
      setPlayerCanPlay,
      currentTime,
      activePlaylist,
      setActivePlayerElement,
      canPlay,
      setLoadedData,
    } = this.props;

    return cloneElement(children, {
      secondsFromMidnight,
      video,
      setPlayerCurrentTime,
      setPlayerCanPlay,
      currentTime,
      activePlaylist,
      setActivePlayerElement,
      canPlay,
      setLoadedData,
    });
  }
}

VideoPlayerContainer.propTypes = {
  children: PropTypes.element,
  secondsFromMidnight: PropTypes.number,
  video: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    secondsFromMidnight: getSecondsFromMidnight(state),
    currentTime: getPlayerCurrentTime(state),
    activePlaylist: getActivePlaylist(state),
    canPlay: getPlayerCanPlay(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setPlayerCurrentTime,
      setPlayerCanPlay,
      setActivePlayerElement,
      setLoadedData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoPlayerContainer);
