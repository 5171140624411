import React from 'react';
import PropTypes from 'prop-types';

import './page.sass';

export default function Page(props) {
  const { page } = props;
  const { html, title } = page;

  const cleanedHTML = html.split('<p>&nbsp;</p>').join('');

  return (
    <div className="page">
      <h2>{title}</h2>
      <div dangerouslySetInnerHTML={{ __html: cleanedHTML }} />
    </div>
  );
}

Page.propTypes = {
  displayableTime: PropTypes.string,
};
