import PropTypes from 'prop-types';
import { cloneElement, Component } from 'react';
import { connect } from 'react-redux';
import { getActivePlaylist } from '../../redux/selectors/player';
import { getPages } from '../../redux/selectors/about';

class NavBarContainer extends Component {
  render() {
    const { children, activePlaylist, pages } = this.props;

    return cloneElement(children, {
      activePlaylist,
      pages,
    });
  }
}

NavBarContainer.propTypes = {
  activePlaylist: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    activePlaylist: getActivePlaylist(state),
    pages: getPages(state),
  };
}
export default connect(mapStateToProps, null)(NavBarContainer);
