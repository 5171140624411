import React, { Component } from "react";
import { isEmpty } from "lodash";
import { Route, Router } from "react-router-dom";
import AboutContainer from "../../containers/about/about";
import HomeContainer from "../../containers/home/home";
import NavBarContainer from "../../containers/nav-bar/nav-bar";
import PlaylistsContainer from "../../containers/playlists/playlists";
import { fetchPlaylists, savePlayerState } from "../../redux/actions/player";
import history from "../../router";
import About from "../about/about";
import Home from "../home/home";
import NavBar from "../navbar/navbar";
import Playlists from "../playlists/playlists";
import VideoCheck from "../video-check/video-check";
import VideoCheckContainer from "../../containers/video-check/video-check";
import Page from "../page/page";
import TestPlayer from "../test-player/test-player";

export default class App extends Component {
  componentDidMount() {
    const { store } = this.props;
    store.dispatch(fetchPlaylists());

    if (!window.location.pathname.includes("clear")) {
      window.addEventListener("beforeunload", this.handleBeforeUnload);
    }
  }

  handleBeforeUnload = () => {
    this.props.store.dispatch(savePlayerState({ clearStorage: false }));
  };

  handleEnterClear = () => {
    this.props.store.dispatch(savePlayerState({ clearStorage: true }));
  };

  renderClear = () => {
    this.handleEnterClear();
    return <div />;
  };

  renderAboutPages = () => {
    const { pages } = this.props;

    if (isEmpty(pages)) {
      return;
    }

    const pageKeys = Object.keys(pages);
    let pageRoutes = [];

    pageKeys.forEach((key) => {
      const { subPages } = pages[key];
      const subPageKeys = Object.keys(subPages);

      subPageKeys.forEach((pageKey) => {
        const page = subPages[pageKey];
        const { path } = page;

        pageRoutes.push(
          <Route
            exact
            key={path}
            path={`/${path}`}
            component={() => <Page page={page} />}
          />
        );
      });
    });

    return pageRoutes;
  };

  render() {
    function home() {
      return (
        <HomeContainer>
          <Home />
        </HomeContainer>
      );
    }

    function videoCheck() {
      return (
        <VideoCheckContainer>
          <VideoCheck />
        </VideoCheckContainer>
      );
    }
    function playlists() {
      return (
        <PlaylistsContainer>
          <Playlists />
        </PlaylistsContainer>
      );
    }

    function about() {
      return (
        <AboutContainer>
          <About />
        </AboutContainer>
      );
    }
    function testPlayer() {
      return <TestPlayer />;
    }

    return (
      <Router history={history}>
        <div>
          <NavBarContainer>
            <NavBar />
          </NavBarContainer>
          <Route exact path="/" component={home} />
          <Route exact path="/playlists" component={playlists} />
          <Route exact path="/about" component={about} />
          <Route exact path="/video-check" component={videoCheck} />
          <Route exact path="/clear" component={this.renderClear} />
          <Route exact path="/test-player" component={testPlayer} />
          {this.renderAboutPages()}
        </div>
      </Router>
    );
  }
}
