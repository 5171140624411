export const UPDATE_ABOUT = 'UPDATE_ABOUT';

const PAGES_ENDPOINT = 'https://thevideoclock.firebaseio.com/pages.json';

const ABOUT_PAGE_ENDPOINT = 'https://thevideoclock.firebaseio.com/about.json';

export function fetchAboutPage() {
  return dispatch => {
    fetch(ABOUT_PAGE_ENDPOINT)
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: UPDATE_ABOUT,
          payload: {
            about: data.PageValue,
          },
        });
      });
  };
}

export function fetchPages() {
  return dispatch => {
    fetch(PAGES_ENDPOINT)
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: UPDATE_ABOUT,
          payload: {
            pages: data,
          },
        });
      });
  };
}
