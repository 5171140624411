import {
  SET_CURRENT_VIDEO,
  SET_PLAYLISTS,
  SET_ACTIVE_PLAYLIST,
  SET_PLAYER_CURRENT_TIME,
  SET_PLAYER_CAN_PLAY,
  SET_ACTIVE_PLAYER_ELEMENT,
  UPDATE_PLAYER_STATE,
} from '../actions/player';
import createReducer from './create-reducer';

export const INITIAL_STATE = {
  activePlaylist: {},
  currentVideo: {},
  nextVideo: {},
  playlists: [],
  canPlay: false,
  currentTime: 0,
  activePlayerElement: {},
  loadedData: false,
};

function setActivePlayerElement(state, { activePlayerElement }) {
  return {
    ...state,
    activePlayerElement,
  };
}

function setActivePlaylist(state, { payload }) {
  const { playlist: activePlaylist } = payload;

  return {
    ...state,
    activePlaylist,
  };
}

function setCurrentVideo(state, { currentVideo, nextVideo }) {
  return {
    ...state,
    currentVideo,
    nextVideo,
  };
}
function setPlaylists(state, { playlists, activePlaylist }) {
  return {
    ...state,
    activePlaylist,
    playlists,
  };
}

function setPlayerCurrentTime(state, { currentTime }) {
  return {
    ...state,
    currentTime,
  };
}

function setPlayerCanPlay(state, { canPlay }) {
  return {
    ...state,
    canPlay,
  };
}

function updatePlayerState(state, props) {
  return {
    ...state,
    ...props,
  };
}

const handlers = {
  [SET_ACTIVE_PLAYER_ELEMENT]: setActivePlayerElement,
  [SET_CURRENT_VIDEO]: setCurrentVideo,
  [SET_PLAYLISTS]: setPlaylists,
  [SET_ACTIVE_PLAYLIST]: setActivePlaylist,
  [SET_PLAYER_CURRENT_TIME]: setPlayerCurrentTime,
  [SET_PLAYER_CAN_PLAY]: setPlayerCanPlay,
  [UPDATE_PLAYER_STATE]: updatePlayerState,
};

export default createReducer(INITIAL_STATE, handlers);
