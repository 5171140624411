import { getCurrentVideo } from '../selectors/player';

const LIKE_ENDPOINT_URL = 'https://thevideoclock.firebaseio.com/likes.json';
const COMMENT_ENDPOINT_URL =
  'https://thevideoclock.firebaseio.com/comments.json';

export function createLike(like) {
  return (dispatch, getState) => {
    const state = getState();
    const currentVideo = getCurrentVideo(state);
    const { CombMinuteID } = currentVideo;
    const now = new Date();
    const formattedNow =
      [now.getMonth() + 1, now.getDate(), now.getFullYear()].join('/') +
      ' ' +
      [now.getHours(), now.getMinutes(), now.getSeconds()].join(':');

    fetch(LIKE_ENDPOINT_URL, {
      method: 'POST',
      body: JSON.stringify({
        CombMinuteID,
        Like: like,
        CreatedAt: formattedNow,
        UUID: state.user.uuid,
      }),
    })
      .then(response => response.json())
      .then(data => {});
  };
}

export function submitComment({ comment, isReportingProblem }) {
  return (dispatch, getState) => {
    const state = getState();
    const currentVideo = getCurrentVideo(state);
    const { CombMinuteID } = currentVideo;

    const now = new Date();
    const formattedNow =
      [now.getMonth() + 1, now.getDate(), now.getFullYear()].join('/') +
      ' ' +
      [now.getHours(), now.getMinutes(), now.getSeconds()].join(':');

    fetch(COMMENT_ENDPOINT_URL, {
      method: 'POST',
      body: JSON.stringify({
        CombMinuteID,
        comment,
        isReportingProblem,
        CreatedAt: formattedNow,
        UUID: state.user.uuid,
      }),
    })
      .then(response => response.json())
      .then(data => {});
  };
}
