import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Playlist } from "./playlist";
import "./playlists.sass";

export default class Playlists extends Component {
  renderPlaylists = () => {
    const { playlists, handlePlaylistClick } = this.props;

    return playlists.map((playlist) => {
      const { fileName } = playlist;
      return (
        <div key={fileName}>
          <Playlist playlistClicked={handlePlaylistClick} playlist={playlist} />
        </div>
      );
    });
  };

  handleResetClicked = () => {
    this.props.savePlayerState({ clearStorage: true });
  };

  render() {
    return (
      <div className="playlists">
        <div className="top-row">
          <Link to="/test-player" className="reset-button">
            Test
          </Link>
          <div onClick={this.handleResetClicked} className="reset-button">
            Reset
          </div>
          <div className="unsynced-title">Play Unsynced</div>
        </div>
        {this.renderPlaylists()}
        <div className="note">
          * Unsynced Playlists are not synced to the time
        </div>
      </div>
    );
  }
}
