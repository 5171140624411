import { isEmpty } from "lodash";
import { getSecondsFromMidnight } from "../selectors/clock";
import { getActivePlaylist, getCurrentVideo } from "../selectors/player";

export const THE_VIDEO_CLOCK_STATE = "theVideoClockState";

export const SET_CURRENT_VIDEO = "SET_CURRENT_VIDEO";
export const SET_PLAYLISTS = "SET_PLAYLISTS";
export const SET_ACTIVE_PLAYLIST = "SET_ACTIVE_PLAYLISTS";
export const SET_PLAYER_CAN_PLAY = "SET_PLAYER_CAN_PLAY";
export const SET_PLAYER_CURRENT_TIME = "SET_PLAYER_CURRENT_TIME";
export const SET_ACTIVE_PLAYER_ELEMENT = "SET_ACTIVE_PLAYER_ELEMENT";
export const UPDATE_PLAYER_STATE = "UPDATE_PLAYER_STATE";
export const TOGGLE_PLAYER_SYNCED = "TOGGLE_PLAYER_SYNCED";

const ALL_PLAYLISTS_ENDPOINT =
  "https://thevideoclock.firebaseio.com/Playlists.json";

const PLAYLIST_LIST_ENDPOINT =
  "https://thevideoclock.firebaseio.com/PlaylistListInfo.json";

export function fetchPlaylists() {
  return async (dispatch) => {
    try {
      const promises = [
        fetch(ALL_PLAYLISTS_ENDPOINT, {
          method: "GET",
        }),
        fetch(PLAYLIST_LIST_ENDPOINT, {
          method: "GET",
        }),
      ];

      const [respPlaylists, respPlaylistInfo] = await Promise.all(promises);
      const playlists = await respPlaylists.json();
      const playlistInfo = await respPlaylistInfo.json();

      const formattedPlaylists = playlistInfo.map((playlist) => {
        const { IsDefault, PlaylistFile, ListName, ListDescription, IsClock } =
          playlist;
        return {
          fileName: PlaylistFile,
          listName: ListName,
          listDescription: ListDescription,
          isClock: IsClock,
          // imports are returned as Module, so we need to convert to array
          videos: playlists[PlaylistFile],
          isDefault: IsDefault,
        };
      });

      const initialAppState =
        JSON.parse(window.localStorage.getItem(THE_VIDEO_CLOCK_STATE)) || {};

      const { activePlaylist: initialActivePlaylist, currentVideo } =
        initialAppState;

      const hasInitialActivePlaylist = !isEmpty(initialActivePlaylist);

      if (hasInitialActivePlaylist) {
        const freshDataActivePlaylist = formattedPlaylists.find(
          (playlist) => playlist.fileName === initialActivePlaylist.fileName
        );

        const shouldUseFreshDataPlaylist =
          initialActivePlaylist.videos[0].DateStamp !==
          freshDataActivePlaylist.videos[0].DateStamp;

        const activePlaylistOverride = shouldUseFreshDataPlaylist
          ? freshDataActivePlaylist
          : initialActivePlaylist;

        if (
          initialAppState &&
          hasInitialActivePlaylist &&
          !isEmpty(currentVideo)
        ) {
          return dispatch({
            type: UPDATE_PLAYER_STATE,
            ...initialAppState,
            currentVideo: {},
            nextVideo: {},
            activePlaylist: activePlaylistOverride,
            playlists: formattedPlaylists.filter((playlist) => playlist),
            resumingFromLocalStorage: true,
          });
        }
      }

      const activePlaylist = formattedPlaylists.find(
        (playlist) => playlist.isDefault
      );

      return dispatch({
        type: SET_PLAYLISTS,
        activePlaylist,
        // Remove null/undefined values
        playlists: formattedPlaylists.filter((playlist) => playlist),
      });
    } catch (err) {
      console.error(err);
    }
  };
}

export function setActivePlayerElement(activePlayerElement) {
  return {
    type: SET_ACTIVE_PLAYER_ELEMENT,
    activePlayerElement,
  };
}

export function playPreviousVideo() {
  return (dispatch, getState) => {
    const state = getState();
    const activePlaylist = getActivePlaylist(state);
    const currentVideo = getCurrentVideo(state);
    const { videos = [] } = activePlaylist;

    const currentVideoIndex = videos.findIndex(
      (item) => item.PlaylistID === getCurrentVideo(state).PlaylistID
    );

    const nextCurrentVideoIndex =
      currentVideoIndex - 1 < 0 ? videos.length - 1 : currentVideoIndex - 1;

    dispatch(setPlayerCurrentTime(0));

    dispatch({
      type: SET_CURRENT_VIDEO,
      currentVideo: videos[nextCurrentVideoIndex],
      nextVideo: currentVideo,
    });
  };
}

export function setCurrentVideo() {
  return (dispatch, getState) => {
    const state = getState();
    const secondsFromMidnight = getSecondsFromMidnight(state);
    const activePlaylist = getActivePlaylist(state);

    const { videos = [], isClock } = activePlaylist;

    let currentVideo, nextVideo, currentVideoIndex;

    if (isClock) {
      currentVideoIndex = videos.findIndex(
        (item) =>
          item.ClockStartTime <= secondsFromMidnight &&
          item.PlayDuration * 60 + item.ClockStartTime > secondsFromMidnight
      );

      currentVideo = videos[currentVideoIndex];

      // Use modulo in case we are at the last item of the playlist.
      nextVideo = videos[(currentVideoIndex + 1) % videos.length];
    } else {
      if (isEmpty(getCurrentVideo(state))) {
        currentVideo = videos[0];
        nextVideo = videos[1];
      } else {
        currentVideoIndex = videos.findIndex(
          (item) => item.PlaylistID === getCurrentVideo(state).PlaylistID
        );

        currentVideo = videos[(currentVideoIndex + 1) % videos.length];
        nextVideo = videos[(currentVideoIndex + 2) % videos.length];
      }
    }
    dispatch(setPlayerCurrentTime(0));

    dispatch({
      type: SET_CURRENT_VIDEO,
      currentVideo,
      nextVideo,
    });

    dispatch({
      type: UPDATE_PLAYER_STATE,
    });
  };
}

export function setActivePlaylist(playlist) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ACTIVE_PLAYLIST,
      payload: { playlist },
    });

    dispatch({
      type: SET_CURRENT_VIDEO,
      currentVideo: {},
      nextVideo: {},
    });

    dispatch(setCurrentVideo());
  };
}

export function setPlayerCurrentTime(currentTime) {
  return {
    type: SET_PLAYER_CURRENT_TIME,
    currentTime,
  };
}

export function setPlayerCanPlay() {
  return {
    type: SET_PLAYER_CAN_PLAY,
    canPlay: true,
  };
}

export function togglePlayerSynced() {
  return (dispatch, getState) => {
    const activePlaylist = getActivePlaylist(getState());
    const { isClock, ...rest } = activePlaylist;

    const nextActivePlaylist = {
      ...rest,
      isClock: !isClock,
      canPlaySynced: isClock,
    };

    dispatch({
      type: SET_ACTIVE_PLAYLIST,
      payload: { playlist: nextActivePlaylist },
    });

    if (!isClock) {
      dispatch(setCurrentVideo());
    }
  };
}

export function savePlayerState({ clearStorage = false }) {
  return (dispatch, getState) => {
    const state = getState();
    const { player } = state;
    const { playlists, activePlayerElement, loadedData, ...rest } = player;

    if (clearStorage) {
      window.localStorage.removeItem(THE_VIDEO_CLOCK_STATE);
      window.location.replace("/");
      return;
    } else {
      window.localStorage.setItem(THE_VIDEO_CLOCK_STATE, JSON.stringify(rest));
    }
  };
}

export function setLoadedData(loadedData) {
  return (dispatch) => {
    return dispatch({
      type: UPDATE_PLAYER_STATE,
      loadedData,
    });
  };
}
