import React, { Component } from "react";
import player from "@vimeo/player";

export default class TestPlayer extends Component {
  componentDidMount() {
    this.player = new player(`test-player`, {
      autoplay: true,
      id: 703552644,
    });
    this.player.play();
  }
  render() {
    return <div id="test-player"></div>;
  }
}
