import PropTypes from 'prop-types';
import { cloneElement, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getActivePlaylist,
  getActivePlayerElement,
  getPlayerCurrentTime,
  getCurrentVideo,
} from '../../redux/selectors/player';
import {
  setCurrentVideo,
  playPreviousVideo,
  togglePlayerSynced,
} from '../../redux/actions/player';
import { createLike, submitComment } from '../../redux/actions/likes';
import { SEEK_RANGE } from '../../utils/constants';

class PlayerControlsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPaused: false,
    };
  }

  handlePlayNextVideo = () => {
    this.props.setCurrentVideo();
  };

  handleFastForward = () => {
    const { currentTime, activePlayerElement } = this.props;
    activePlayerElement.player.setCurrentTime(currentTime + SEEK_RANGE);
  };

  handleRewind = () => {
    const { currentTime, activePlayerElement } = this.props;
    activePlayerElement.player.setCurrentTime(currentTime - SEEK_RANGE);
  };

  handlePlayPauseClick = () => {
    const { activePlayerElement, activePlaylist } = this.props;
    const { isClock } = activePlaylist;
    const { isPaused } = this.state;
    const { player } = activePlayerElement;

    if (isClock) {
      this.props.togglePlayerSynced();
    }

    player.getPaused().then(paused => {
      if (paused) {
        player.play();
      } else {
        player.pause();
      }
      this.setState({
        isPaused: !isPaused,
      });
    });
  };

  handleLikeClick = like => {
    this.props.createLike(like);
  };

  handleSubmitComment = ({ comment, isReportingProblem }) => {
    this.props.submitComment({
      comment,
      isReportingProblem,
    });
  };

  render() {
    const {
      children,
      activePlaylist,
      setCurrentVideo,
      playPreviousVideo,
      currentTime,
      currentVideo,
      togglePlayerSynced,
    } = this.props;
    const { isPaused } = this.state;

    return cloneElement(children, {
      activePlaylist,
      setCurrentVideo,
      playPreviousVideo,
      currentTime,
      currentVideo,
      togglePlayerSynced,
      isPaused,
      handleSubmitComment: this.handleSubmitComment,
      onLikeClick: this.handleLikeClick,
      onPlayPauseClick: this.handlePlayPauseClick,
      onFastForwardClick: this.handleFastForward,
      onRewindClick: this.handleRewind,
      playNextVideo: this.handlePlayNextVideo,
    });
  }
}

PlayerControlsContainer.propTypes = {
  children: PropTypes.element.isRequired,
};

function mapStateToProps(state) {
  return {
    activePlaylist: getActivePlaylist(state),
    activePlayerElement: getActivePlayerElement(state),
    currentTime: getPlayerCurrentTime(state),
    currentVideo: getCurrentVideo(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCurrentVideo,
      playPreviousVideo,
      togglePlayerSynced,
      createLike,
      submitComment,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlayerControlsContainer);
