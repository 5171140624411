import { UPDATE_CLOCK } from '../actions/clock';
import createReducer from './create-reducer';

const INITIAL_STATE = {
  timeInSeconds: 0,
};

function updateClock(state, { payload }) {
  return {
    ...state,
    ...payload,
  };
}

const handlers = {
  [UPDATE_CLOCK]: updateClock,
};

export default createReducer(INITIAL_STATE, handlers);
