import classNames from 'classnames';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Playlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unsyncedChecked: false,
    };
  }

  componentDidMount() {
    const { playlist } = this.props;
    if (!playlist.isClock) {
      this.setState({
        unsyncedChecked: true,
      });
    }
  }

  playlistClicked = () => {
    const { playlist } = this.props;
    const { unsyncedChecked } = this.state;

    this.props.playlistClicked({
      ...playlist,
      isClock: !unsyncedChecked,
    });
  };

  handleCheckboxClick = () => {
    const { playlist } = this.props;
    const { unsyncedChecked } = this.state;
    if (playlist.isClock) {
      this.setState({
        unsyncedChecked: !unsyncedChecked,
      });
    }
  };

  render() {
    const { unsyncedChecked } = this.state;
    const { playlist } = this.props;
    const { listName, listDescription } = playlist;

    const checkMarkup = unsyncedChecked ? 'x' : null;

    const unsyncedCheckboxClasses = classNames({
      'unsynced-checkbox': true,
      checked: unsyncedChecked,
      clickable: playlist.isClock,
    });

    return (
      <div className="playlist">
        <Link className="play-button" to={'/'} onClick={this.playlistClicked}>
          Play
        </Link>
        <div className="title">
          {listName}
          {!playlist.isClock && (
            <div className="unsynced-descriptor">Unsynced Playlist</div>
          )}
        </div>
        <p className="description">{listDescription}</p>
        <div
          onClick={this.handleCheckboxClick}
          className={unsyncedCheckboxClasses}
        >
          {checkMarkup}
        </div>
      </div>
    );
  }
}
