import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { cloneElement, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCurrentTime } from '../../redux/actions/clock';
import { setCurrentVideo } from '../../redux/actions/player';
import {
  getDisplayableTime,
  getSecondsFromMidnight,
} from '../../redux/selectors/clock';
import {
  getCurrentVideo,
  getActivePlaylist,
  getPlayerCurrentTime,
  getResumingFromLocalStorage,
} from '../../redux/selectors/player';
import { SECOND } from '../../utils/constants';

class ClockContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingNextVideo: false,
    };

    this.clockInterval = null;
  }

  componentDidMount() {
    this.startClock();
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps) {
      return;
    }

    const {
      secondsFromMidnight,
      resumingFromLocalStorage,
      activePlaylist,
      currentVideo,
    } = this.props;
    const { isClock } = activePlaylist;

    if (
      (isClock || !resumingFromLocalStorage) &&
      !secondsFromMidnight &&
      nextProps.secondsFromMidnight
    ) {
      this.props.setCurrentVideo();
    }
    if (currentVideo.PlaylistID !== nextProps.currentVideo.PlaylistID) {
      this.setState({
        loadingNextVideo: false,
      });
    }
    this.checkShouldPlayNextVideo(nextProps);
  }

  componentWillUnmount() {
    clearInterval(this.clockInterval);
  }

  checkShouldPlayNextVideo = nextProps => {
    const {
      currentVideo,
      secondsFromMidnight,
      activePlaylist,
      currentTime,
    } = this.props;
    const { loadingNextVideo } = this.state;
    const { isClock } = activePlaylist;
    const { PlayDuration, ClockStartTime, OffsetSec } = currentVideo;
    if (!isEmpty(currentVideo)) {
      if (isClock) {
        if (ClockStartTime + PlayDuration * 60 < secondsFromMidnight) {
          this.props.setCurrentVideo();
        }
      } else if (
        !loadingNextVideo &&
        currentTime + 1 > OffsetSec + PlayDuration * 60
      ) {
        this.setState({ loadingNextVideo: true });
        this.props.setCurrentVideo();
      }
    }
  };

  startClock = () => {
    this.clockInterval = setInterval(this.props.setCurrentTime, SECOND);
  };

  render() {
    const { children, displayableTime } = this.props;
    return cloneElement(children, {
      displayableTime,
    });
  }
}

ClockContainer.propTypes = {
  children: PropTypes.element.isRequired,
  currentVideo: PropTypes.object,
  displayableTime: PropTypes.string,
  secondsFromMidnight: PropTypes.number,
  setCurrentTime: PropTypes.func,
  setCurrentVideo: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    displayableTime: getDisplayableTime(state),
    secondsFromMidnight: getSecondsFromMidnight(state),
    currentVideo: getCurrentVideo(state),
    activePlaylist: getActivePlaylist(state),
    currentTime: getPlayerCurrentTime(state),
    resumingFromLocalStorage: getResumingFromLocalStorage(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCurrentTime,
      setCurrentVideo,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClockContainer);
