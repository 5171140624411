import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { cloneElement, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchPages } from '../../redux/actions/about';
import { setCurrentVideo } from '../../redux/actions/player';
import { getPages } from '../../redux/selectors/about';
import { getActivePlaylist } from '../../redux/selectors/player';

class AppContainer extends Component {
  componentDidMount() {
    this.props.fetchPages();
  }

  componentDidUpdate(prevProps) {
    if (
      isEmpty(prevProps.activePlaylist) &&
      !isEmpty(this.props.activePlaylist)
    ) {
      this.props.setCurrentVideo();
    }
  }

  render() {
    const { children, pages } = this.props;

    return cloneElement(children, {
      pages,
    });
  }
}

AppContainer.propTypes = {
  pages: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    pages: getPages(state),
    activePlaylist: getActivePlaylist(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPages,
      setCurrentVideo,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
