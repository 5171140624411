import { formatDisplayableTime } from '../../utils/time';

export function getSecondsFromMidnight({ clock }) {
  return clock.secondsFromMidnight;
}

export function getDisplayableTime({ clock }) {
  const secondsFromMidnight = getSecondsFromMidnight({ clock });
  if (secondsFromMidnight) {
    return formatDisplayableTime(secondsFromMidnight);
  }
  return null;
}
